import { useTranslation } from 'react-i18next';
import { FaLinkedinIn, FaGithub } from 'react-icons/fa';
import { AiFillPhone } from 'react-icons/ai';
import { MdEmail } from 'react-icons/md';
import { PATHS } from '../../../config/consts';
import profile from '../../../assets/images/home/profile.jpg';
import useInView from '../../../hooks/useInView';

const Header = () => {
    const { t } = useTranslation();
    const [ref, isInView] = useInView();

    const navigateToContactMe = () => {
        const element = document.querySelector('#contact');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <header>
            <div className='container header-container'>
                <div className={`row align-items-center ${isInView ? 'fade-up' : ''}`}>
                    <div className='col-12 col-md-6' ref={ref}>
                        <div className='content-container'>
                            <h4 className='header-welcome'>{t('header-welcome')}</h4>
                            <h1 className='header-title'>
                                <span className='text-tertiary'>{t('header-title.part1')}</span>
                                <span>{t('header-title.part2')}</span>
                            </h1>
                            <h3 className='header-subtitle'>{t('header-subtitle')}</h3>
                            <div className='header-social-wrapper-small'>
                                <div className='social-item-1'>
                                    <a
                                        className='btn btn-tertiary btn-circle text-primary mx-2'
                                        href={`tel:${t('about-phone')}`}
                                    >
                                        <AiFillPhone />
                                    </a>
                                </div>
                                <div className='social-item-2'>
                                    <a
                                        className='btn btn-tertiary btn-circle text-primary mx-2'
                                        href={`mailto:${t('about-email')}`}
                                    >
                                        <MdEmail />
                                    </a>
                                </div>
                                <div className='social-item-3'>
                                    <a
                                        className='btn btn-tertiary btn-circle text-primary mx-2'
                                        href={t('linkedin-link')}
                                    >
                                        <FaLinkedinIn />
                                    </a>
                                </div>
                                <div className='social-item-4'>
                                    <a
                                        className='btn btn-tertiary btn-circle text-primary mx-2'
                                        href={t('github-link')}
                                    >
                                        <FaGithub />
                                    </a>
                                </div>
                            </div>
                            <div className='d-none d-md-flex mt-2'>
                                <a href={PATHS.RESUME} download className='btn btn-primary btn-rounded mx-2'>
                                    {t('header-resume')}
                                </a>
                                <button onClick={navigateToContactMe} className='btn btn-tertiary btn-rounded mx-2'>
                                    {t('header-contact')}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-5'>
                        {/* <div className='header-sticker'>
                            <div className='badge bg-black text-tertiaty'>Full Stack Developer</div>
                        </div> */}
                        <div className='header-profile'>
                            <img className='profile-img' src={profile} alt='Profile image' />
                        </div>
                    </div>
                    <div className='col-12 col-md-1'>
                        <div className='d-flex justify-content-center'>
                            <div className='header-social-wrapper-large'>
                                <div className='social-item-1'>
                                    <a
                                        className='btn btn-tertiary btn-circle text-primary mx-2 mt-5'
                                        href={`tel:${t('about-phone')}`}
                                    >
                                        <AiFillPhone />
                                    </a>
                                </div>
                                <div className='social-item-2'>
                                    <a
                                        className='btn btn-tertiary btn-circle text-primary mx-2 mt-5'
                                        href={`mailto:${t('about-email')}`}
                                    >
                                        <MdEmail />
                                    </a>
                                </div>
                                <div className='social-item-3'>
                                    <a
                                        className='btn btn-tertiary btn-circle text-primary mx-2 mt-5'
                                        href={t('linkedin-link')}
                                    >
                                        <FaLinkedinIn />
                                    </a>
                                </div>
                                <div className='social-item-4'>
                                    <a
                                        className='btn btn-tertiary btn-circle text-primary mx-2 mt-5'
                                        href={t('github-link')}
                                    >
                                        <FaGithub />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
