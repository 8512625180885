import { useTranslation } from 'react-i18next';
import Counter from '../../common/counter/Counter';

const About = () => {
    const { t, i18n } = useTranslation();
    const isRtl = i18n.language === 'he';

    return (
        <section id='about'>
            <div className='container'>
                <div className='text-center'>
                    <h3 className='section-title'>
                        <span className={`${isRtl ? 'text-secondary' : 'text-primary'}`}>{t('about-title.part1')}</span>
                        <span className={`${isRtl ? 'text-primary' : 'text-secondary'}`}>{t('about-title.part2')}</span>
                    </h3>
                </div>
                <div className='about-content-wrapper'>
                    <div className='about-content'>{t('about-content')}</div>
                </div>
                <div className='about-experience'>
                    <h2 className='about-experience-highlight'>
                        <Counter number={parseInt(t('about-highlight.part1'))} />
                    </h2>
                    <div className='about-experience-text'>
                        <span>{t('about-highlight.part2')}</span>
                        <br />
                        <span>{t('about-highlight.part3')}</span>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
