import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer>
            <div className='container'>
                <div className='d-flex align-items-center justify-content-center'>
                    <div className='text-lg-center'>
                        <div>
                            {t('copy-right.part1')} &copy; <span className='text-primary'>{t('copy-right.part2')}</span>
                            {t('copy-right.part3')}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
