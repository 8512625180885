import html_icon from '../../../assets/icons/technologies/html-icon.jpg';
import css_icon from '../../../assets/icons/technologies/css-icon.jpg';
import javascript_icon from '../../../assets/icons/technologies/javascript-icon.jpg';
import react_icon from '../../../assets/icons/technologies/react-icon.jpg';
import redux_icon from '../../../assets/icons/technologies/redux-icon.jpg';
import bootstrap_icon from '../../../assets/icons/technologies/bootstrap-icon.jpg';
import typescript_icon from '../../../assets/icons/technologies/typescript-icon.jpg';
import nodejs_icon from '../../../assets/icons/technologies/nodejs-icon.jpg';
import express_icon from '../../../assets/icons/technologies/express-icon.jpg';
import graphql_icon from '../../../assets/icons/technologies/graphql-icon.jpg';
import mongodb_icon from '../../../assets/icons/technologies/mongodb-icon.jpg';
import firebase_icon from '../../../assets/icons/technologies/firebase-icon.jpg';
import flutter_icon from '../../../assets/icons/technologies/flutter-icon.jpg';
import dart_icon from '../../../assets/icons/technologies/dart-icon.jpg';
import materialui_icon from '../../../assets/icons/technologies/materialui-icon.jpg';
import appstore_icon from '../../../assets/icons/technologies/appstore-icon.jpg';
import googleplay_icon from '../../../assets/icons/technologies/googleplay-icon.jpg';
import github_icon from '../../../assets/icons/technologies/github-icon.jpg';

const skills = [
    {
        _id: 'skill-item-1-title',
        icon: html_icon,
        name: 'skill-item-1-title',
    },
    {
        _id: 'skill-item-2-title',
        icon: css_icon,
        name: 'skill-item-2-title',
    },
    {
        _id: 'skill-item-3-title',
        icon: javascript_icon,
        name: 'skill-item-3-title',
    },
    {
        _id: 'skill-item-4-title',
        icon: react_icon,
        name: 'skill-item-4-title',
    },
    {
        _id: 'skill-item-5-title',
        icon: redux_icon,
        name: 'skill-item-5-title',
    },
    {
        _id: 'skill-item-6-title',
        icon: bootstrap_icon,
        name: 'skill-item-6-title',
    },
    {
        _id: 'skill-item-7-title',
        icon: typescript_icon,
        name: 'skill-item-7-title',
    },
    {
        _id: 'skill-item-8-title',
        icon: nodejs_icon,
        name: 'skill-item-8-title',
    },
    {
        _id: 'skill-item-9-title',
        icon: express_icon,
        name: 'skill-item-9-title',
    },
    {
        _id: 'skill-item-10-title',
        icon: graphql_icon,
        name: 'skill-item-10-title',
    },
    {
        _id: 'skill-item-11-title',
        icon: mongodb_icon,
        name: 'skill-item-11-title',
    },
    {
        _id: 'skill-item-12-title',
        icon: firebase_icon,
        name: 'skill-item-12-title',
    },
    {
        _id: 'skill-item-13-title',
        icon: flutter_icon,
        name: 'skill-item-13-title',
    },
    {
        _id: 'skill-item-14-title',
        icon: dart_icon,
        name: 'skill-item-14-title',
    },
    {
        _id: 'skill-item-15-title',
        icon: materialui_icon,
        name: 'skill-item-15-title',
    },
    {
        _id: 'skill-item-16-title',
        icon: appstore_icon,
        name: 'skill-item-16-title',
    },
    {
        _id: 'skill-item-17-title',
        icon: googleplay_icon,
        name: 'skill-item-17-title',
    },
    {
        _id: 'skill-item-18-title',
        icon: github_icon,
        name: 'skill-item-18-title',
    },
];

export default skills;
