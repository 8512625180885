import dotenv from 'dotenv';
dotenv.config();

export const CONTACT_FORM = 'contactForm';

export const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
export const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
export const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

export const PATHS = {
    HOME: '/',
    RESUME: '/CV - Avi Peretz.pdf',
};
