const Card = ({ isLastItem, icon, name, description, classes }) => {
    return (
        <div className={`col-md-4 mb-${isLastItem ? '0' : '4'} mb-md-0`}>
            <div className={`card ${classes}`}>
                {icon && <img className='card-icon' src={icon} alt='Service icon' />}
                {name && <h4 className='card-title'>{name}</h4>}
                {description && <p className='card-subtitle'>{description}</p>}
            </div>
        </div>
    );
};

export default Card;
