import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Router from './router';

const App = () => {
    const { i18n } = useTranslation();
    document.body.dir = i18n.dir();

    return (
        <BrowserRouter>
            <Router />
        </BrowserRouter>
    );
};

export default App;
