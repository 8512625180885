import { useTranslation } from 'react-i18next';
import projects from './data';
import BoxItem from '../../common/boxItem';
import useInView from '../../../hooks/useInView';

const Projects = () => {
    const { i18n, t } = useTranslation();
    const isRtl = i18n.language === 'he';
    const [ref, isInView] = useInView();

    const renderProject = () => {
        return projects.map((item, index) => (
            <BoxItem
                key={item._id}
                title={t(item.title)}
                subtitle={t(item.subtitle)}
                description={t(item.description)}
                link={item.link}
                appStoreLink={item.appStoreLink}
                googlePlayLink={item.googlePlayLink}
                thumbnail={t(item.thumbnail)}
                images={item.images}
                inProgress={item.inProgress}
                isMobileApp={item.isMobileApp}
                isLastItem={projects.length === index + 1}
                classes={isInView ? 'fade-up' : ''}
            />
        ));
    };

    return (
        <section id='projects'>
            <div className='container-xl' ref={ref}>
                <div className='text-center'>
                    <h3 className='section-title'>
                        <span className={`${isRtl ? 'text-primary' : 'text-tertiary'}`}>
                            {t('projects-title.part1')}
                        </span>
                        <span className={`${isRtl ? 'text-tertiary' : 'text-primary'}`}>
                            {t('projects-title.part2')}
                        </span>
                    </h3>
                </div>
                <div className='row justify-content-center'>{renderProject()}</div>
            </div>
        </section>
    );
};

export default Projects;
