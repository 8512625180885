import useWindowSize from '../../hooks/useWindowSize';
import Navbar from '../common/navbar';
import Header from './header';
import About from './about';
import Services from './services';
import Projects from './projects';
import Resume from './resume';
import ContactMe from './contactMe';
import Footer from './footer';
import Skills from './skills';

const Home = () => {
    const windowSize = useWindowSize();
    const isLargeScreen = windowSize > 767;

    const navLinks = [
        { id: 1, name: 'home-nav-item', target: '#about' },
        { id: 2, name: 'resume-nav-item', target: '#resume' },
        { id: 3, name: 'services-nav-item', target: '#services' },
        { id: 4, name: 'projects-nav-item', target: '#projects' },
        { id: 5, name: 'skills-nav-item', target: '#skills' },
        { id: 6, name: 'contact-nav-item', target: '#contact' },
    ];

    return (
        <main id='home-screen'>
            <Navbar brandText={'brand-name'} navLinks={navLinks} isLargeScreen={isLargeScreen} />
            <Header />
            <About />
            <Resume />
            <Services />
            <Projects />
            <Skills />
            <ContactMe />
            <Footer />
        </main>
    );
};

export default Home;
