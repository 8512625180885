const FormField = ({ input, placeholder, type, meta: { touched, error }, t, isRtl, isTextarea }) => {
    return (
        <div className={`form-group ${isTextarea ? 'form-group-textarea' : ''} mb-md-0`}>
            {isTextarea ? (
                <textarea {...input} className='form-control' placeholder={placeholder}></textarea>
            ) : (
                <input
                    {...input}
                    className={`form-control ${isRtl ? 'text-end' : ''}`}
                    placeholder={placeholder}
                    type={type}
                />
            )}
            {touched && error && <div className='invalid-input'>{t(error)}</div>}
        </div>
    );
};

export default FormField;
