import CountUp from 'react-countup';
// import { useInView } from 'react-cool-inview';
import useInView from '../../../hooks/useInView';

const Counter = ({ number, title, duration = 2 }) => {
    const [ref, isInView] = useInView();

    return (
        <div className='number' ref={ref}>
            {isInView && <CountUp duration={duration} className='counter' end={number} />}
            <span>{title}</span>
        </div>
    );
};

export default Counter;
