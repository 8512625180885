import { Routes, Route } from 'react-router-dom';
import Home from '../components/home';
import { PATHS } from '../config/consts';

const Router = () => (
    <Routes>
        <Route path={PATHS.HOME} exact element={<Home />}></Route>
    </Routes>
);

export default Router;
