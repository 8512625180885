import sce_logo from '../../../assets/logos/sce-logo.jpg';
import cambium_logo from '../../../assets/logos/cambium-logo.jpg';
import amdocs_logo from '../../../assets/logos/amdocs-logo.jpg';

export const educationItems = [
    {
        _id: 'sce',
        logo: sce_logo,
        title: 'resume-education-item-1-title',
        subtitle: 'resume-education-item-1-subtitle',
        badgeText: 'resume-education-item-1-badgeText',
    },
];

export const experienceItems = [
    {
        _id: 'amdocs',
        logo: amdocs_logo,
        title: 'resume-experience-item-1-title',
        subtitle: 'resume-experience-item-1-subtitle',
        badgeText: 'resume-experience-item-1-badgeText',
    },
    {
        _id: 'cambium',
        logo: cambium_logo,
        title: 'resume-experience-item-2-title',
        subtitle: 'resume-experience-item-2-subtitle',
        badgeText: 'resume-experience-item-2-badgeText',
    },
];
