import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm, reset } from 'redux-form';
import { CONTACT_FORM, SERVICE_ID, TEMPLATE_ID, PUBLIC_KEY } from '../../../config/consts';
import validate from '../../../utils/validateForm';
import Modal from '../../common/modal';
import emailjs from '@emailjs/browser';
import success_icon from '../../../assets/icons/success-icon.jpg';
import warning_icon from '../../../assets/icons/warning-icon.jpg';
import FormField from '../../common/formField';
import useInView from '../../../hooks/useInView';

const ContactMe = (props) => {
    const { i18n, t } = useTranslation();
    const isRtl = i18n.language === 'he';
    const [ref, isInView] = useInView();

    const { invalid } = props;
    const dispatch = useDispatch();

    const [modalData, setModalData] = useState({
        showModal: false,
        isLoading: false,
        isSuccess: false,
        modalTitle: '',
        modalBody: '',
        modalButtonText: '',
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setModalData({ ...modalData, showModal: true, isLoading: true });
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY).then(
            (result) => {
                setModalData({
                    showModal: true,
                    isSuccess: true,
                    modalTitle: t('success-modal-title'),
                    modalBody: t('success-modal-body'),
                    modalButtonText: t('close'),
                    isLoading: false,
                });
            },
            (error) => {
                setModalData({
                    showModal: true,
                    isSuccess: false,
                    modalTitle: t('error-modal-title'),
                    modalBody: t('error-modal-body'),
                    modalButtonText: t('close'),
                    isLoading: false,
                });
            }
        );
    };

    const handleClose = () => {
        setModalData({ ...modalData, showModal: false });
        dispatch(reset('contactForm'));
    };

    return (
        <section id='contact'>
            <div className='container' ref={ref}>
                <div className='text-center'>
                    <h3 className='section-title'>
                        <span className='text-tertiary'>{t('contact-title.part1')}</span>
                        <span className='text-primary'>{t('contact-title.part2')}</span>
                        <span className='text-tertiary'>{t('contact-title.part3')}</span>
                        <span className='text-primary'>{t('contact-title.part4')}</span>
                    </h3>
                </div>
                <form id='contactForm' onSubmit={handleSubmit} className={`${isInView ? 'fade-up' : ''}`}>
                    <div className='mb-5'>
                        <div className='input-wrapper'>
                            <Field
                                name='name'
                                type='text'
                                component={FormField}
                                placeholder={t('contact-name-placeholder')}
                                t={t}
                                isRtl={isRtl}
                            />
                        </div>
                        <div className='input-wrapper'>
                            <Field
                                name='email'
                                type='email'
                                component={FormField}
                                placeholder={t('contact-email-placeholder')}
                                t={t}
                                isRtl={isRtl}
                            />
                        </div>
                        <div className='input-wrapper'>
                            <Field
                                name='phone'
                                type='tel'
                                component={FormField}
                                placeholder={t('contact-phone-placeholder')}
                                t={t}
                                isRtl={isRtl}
                            />
                        </div>
                        <div className='textarea-wrapper'>
                            <Field
                                name='message'
                                component={FormField}
                                placeholder={t('contact-message-placeholder')}
                                t={t}
                                isTextarea
                            />
                        </div>
                    </div>
                    <div className='d-none' id='submitErrorMessage'>
                        <div className='text-center text-danger mb-3'>{t('error-sending-message')}</div>
                    </div>
                    <div className='text-center'>
                        <button
                            className={`btn btn-primary btn-rounded text-uppercase ${invalid ? 'disabled' : ''}`}
                            id='submitButton'
                            type='submit'
                        >
                            {t('send-message')}
                        </button>
                    </div>
                    <Modal
                        classes='modal'
                        show={modalData.showModal}
                        centered
                        handleClose={handleClose}
                        btnPrimaryHandler={handleClose}
                        title={modalData.modalTitle}
                        body={modalData.modalBody}
                        btnPrimaryText={modalData.modalButtonText}
                        isRtl={isRtl}
                        statusIcon={modalData.isSuccess ? success_icon : warning_icon}
                        isLoading={modalData.isLoading}
                    />
                </form>
            </div>
        </section>
    );
};

export default reduxForm({
    form: CONTACT_FORM,
    validate,
})(ContactMe);
