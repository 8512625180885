import web_dev_icon from '../../../assets/icons/web-dev-icon.jpg';
import mobile_dev_icon from '../../../assets/icons/mobile-dev-icon.jpg';
import server_dev_icon from '../../../assets/icons/server-dev-icon.jpg';

const services = [
    {
        _id: 's1',
        icon: web_dev_icon,
        name: 'services-item-1-title',
        description: 'services-item-1-description',
    },
    {
        _id: 's2',
        icon: mobile_dev_icon,
        name: 'services-item-2-title',
        description: 'services-item-2-description',
    },
    {
        _id: 's3',
        icon: server_dev_icon,
        name: 'services-item-3-title',
        description: 'services-item-3-description',
    },
];

export default services;
