import { useTranslation } from 'react-i18next';
import skills from './data';
import useInView from '../../../hooks/useInView';

const Skills = () => {
    const { t } = useTranslation();
    const [ref, isInView] = useInView();

    const renderSkills = () => {
        return skills.map((skill) => (
            <div key={skill._id} className='col-4 col-md-3 col-lg-2'>
                <div className={`skill-item ${isInView ? 'fade-up' : ''}`}>
                    <img className='skill-icon' src={skill.icon} alt={t(skill.name)} />
                    <h6 className='skill-title'>{t(skill.name)}</h6>
                </div>
            </div>
        ));
    };

    return (
        <section id='skills'>
            <div className='text-center'>
                <h3 className='section-title'>
                    <span className='text-primary'>{t('skills-title.part1')}</span>
                    <span className='text-secondary'>{t('skills-title.part2')}</span>
                    <span className='text-primary'>{t('skills-title.part3')}</span>
                    <span className='text-secondary'>{t('skills-title.part4')}</span>
                </h3>
            </div>
            <div className='container' ref={ref}>
                <div className='row skills-row'>{renderSkills()}</div>
            </div>
        </section>
    );
};

export default Skills;
