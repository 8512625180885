import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import language_icon from '../../../assets/icons/language-icon.jpg';
import menu_icon from '../../../assets/icons/menu-icon.jpg';
import close_icon from '../../../assets/icons/close-icon.jpg';
import { PATHS } from '../../../config/consts';

const Navbar = (props) => {
    const { navLinks, isLargeScreen, brandText, brandWidget } = props;

    const { i18n, t } = useTranslation();
    const isRtl = i18n.language === 'he';

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [selectedLink, setSelectedLink] = useState(navLinks[0]);
    const navbarRef = useRef(null);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isLargeScreen, selectedLink]);

    useEffect(() => {
        if (isSidebarOpen) {
            document.addEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isSidebarOpen]);

    const handleClickOutside = (event) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target)) {
            const navbarToggler = document.querySelector('.navbar-toggler');
            navbarToggler.click();
        }
    };

    const handleNavbarScroll = () => {
        const navbar = document.querySelector('.navbar');

        if (isLargeScreen && window.scrollY !== 0) {
            navbar.classList.add('scrolled');
        } else {
            navbar.classList.remove('scrolled');
        }
    };

    const handleSectionHighlight = () => {
        const fromTop = window.scrollY + window.innerHeight / 20;
        let currentSection = navLinks[0];

        for (let navlink of navLinks) {
            const section = document.querySelector(navlink.target);
            if (section) {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.offsetHeight;

                if (fromTop >= sectionTop && fromTop < sectionTop + sectionHeight) {
                    currentSection = navlink;
                    break;
                }
            }
        }

        if (currentSection.id !== selectedLink.id) {
            setSelectedLink(currentSection);
        }
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
            setSelectedLink(navLinks[navLinks.length - 1]);
        }
    };

    const handleScroll = () => {
        handleNavbarScroll();
        handleSectionHighlight();
    };

    const insidePageNavigation = (target) => {
        const element = document.querySelector(target);
        const navbarToggler = document.querySelector('.navbar-toggler');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
        if (navbarToggler) {
            navbarToggler.click();
        }
    };

    const toggleSidebar = () => {
        setIsSidebarOpen((prev) => !prev);
    };

    const changeLanguage = (e) => {
        i18n.changeLanguage(e.target.value);
    };

    const renderBrand = () => {
        if (brandWidget && isLargeScreen) {
            return brandWidget;
        } else if (brandText) {
            return (
                <div
                    className={`navbar-brand ${isRtl ? 'me-0 ms-3' : ''} d-none d-md-block`}
                    onClick={() => insidePageNavigation('#root')}
                >
                    {t(brandText)}
                </div>
            );
        } else {
            return null;
        }
    };

    const renderNavLinks = () => {
        return navLinks.map((navLink) => {
            const isActive = selectedLink.id === navLink.id;
            return (
                <li className='nav-item' key={navLink.id}>
                    <a
                        className={`nav-link ${isActive ? 'active' : ''}`}
                        onClick={() => insidePageNavigation(navLink.target)}
                        href={navLink.href}
                        download={navLink.download}
                    >
                        {t(navLink.name)}
                    </a>
                </li>
            );
        });
    };

    return (
        <nav ref={navbarRef} className={`navbar navbar-expand-md fixed-top ${isSidebarOpen ? 'sidebar' : ''}`}>
            <div className='container'>
                {renderBrand()}
                <div className={`${isRtl ? 'ms' : 'me'}-auto`}>
                    {!isLargeScreen && (
                        <button
                            className='navbar-toggler'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#navbarResponsive'
                            aria-controls='navbarResponsive'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={toggleSidebar}
                        >
                            <div className={`${isSidebarOpen ? 'navbar-toggler-box-closed' : 'navbar-toggler-box'}`}>
                                <img
                                    className='navbar-toggler-icon'
                                    src={isSidebarOpen ? close_icon : menu_icon}
                                    alt='toggle'
                                />
                            </div>
                        </button>
                    )}
                </div>
                <div className='collapse navbar-collapse' id='navbarResponsive'>
                    <>
                        <ul className='navbar-nav text-uppercase py-4 py-md-0 px-0 mx-auto'>{renderNavLinks()}</ul>
                        <ul className='navbar-nav text-uppercase px-0'>
                            {!isLargeScreen && (
                                <li className='nav-item'>
                                    <a className='nav-link' href={PATHS.RESUME} download={true}>
                                        {t('header-resume')}
                                    </a>
                                </li>
                            )}
                            <li className='nav-item dropdown'>
                                <div
                                    className='nav-link'
                                    id='navbarDropdown'
                                    role='button'
                                    data-bs-toggle='dropdown'
                                    aria-expanded='false'
                                >
                                    {isLargeScreen ? (
                                        <img className='language-icon' src={language_icon} alt='language icon' />
                                    ) : (
                                        <div>{t('language-nav-item')}</div>
                                    )}
                                </div>
                                <ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
                                    <li>
                                        <button
                                            className={`dropdown-item ${isRtl ? 'text-end' : ''}`}
                                            onClick={changeLanguage}
                                            value='he'
                                        >
                                            עברית
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className={`dropdown-item ${isRtl ? 'text-end' : ''}`}
                                            onClick={changeLanguage}
                                            value='en'
                                        >
                                            English
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
