import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import close_icon from '../../../assets/icons/close-icon.jpg';
import Modal from '../../common/modal';
import ImageGallery from 'react-image-gallery';
import app_store_badge from '../../../assets/icons/app-store-badge.jpg';
import google_play_badge from '../../../assets/icons/google-play-badge.jpg';

const BoxItem = (props) => {
    const { t } = useTranslation();
    const {
        title,
        subtitle,
        description,
        link,
        appStoreLink,
        googlePlayLink,
        thumbnail,
        images,
        inProgress,
        isMobileApp,
        isLastItem,
        classes,
    } = props;

    const [showModal, setShowModal] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleScreenChange = (isFullscreen) => {
        setIsFullscreen(isFullscreen);
    };

    return (
        <>
            <div className='col-lg-4 col-sm-6' onClick={handleShow}>
                <div className={`box-item ${!isLastItem ? 'mb-4' : 'mb-0'} ${classes}`}>
                    {thumbnail && (
                        <img
                            className='img-fluid box-item-img'
                            src={thumbnail}
                            alt='...'
                            style={isMobileApp ? { objectFit: 'contain' } : {}}
                        />
                    )}
                    <div className='box-item-caption'>
                        {title && <div className='box-item-title'>{title}</div>}
                        {subtitle && <div className='box-item-subtitle'>{subtitle}</div>}
                    </div>
                </div>
            </div>
            <Modal classes='modal-xl' show={showModal} centered handleClose={handleClose}>
                <div className='p-3'>
                    <div className='close-modal-icon-wrapper' onClick={handleClose}>
                        <img className='close-modal-icon' src={close_icon} alt='close modal' />
                    </div>
                    <div className='container'>
                        <div className='row justify-content-center align-items-center gx-5'>
                            <div className='col-lg-8 col-md-12 mt-2 mb-4 mt-lg-3 mb-lg-3'>
                                <ImageGallery
                                    className={`${isFullscreen ? 'fullscreen' : ''}`}
                                    items={images}
                                    showPlayButton={false}
                                    showBullets={isFullscreen}
                                    showThumbnails={!isFullscreen}
                                    onScreenChange={handleScreenChange}
                                    lazyLoad={true}
                                />
                            </div>
                            <div className='col-lg-4 col-md-12'>
                                {title && <h3 className='text-secondary'>{title}</h3>}
                                {subtitle && <p className='text-muted'>{subtitle}</p>}
                                {description && <p className='mb-4'>{description}</p>}
                                <div className='mb-4'>
                                    {inProgress && <span className='badge bg-primary'>{t('project-in-progress')}</span>}
                                    {link && (
                                        <a href={link} target='_blank' rel='noopener noreferrer'>
                                            {t('project-link')}
                                        </a>
                                    )}
                                    {(appStoreLink || googlePlayLink) && (
                                        <div className='d-flex d-lg-block d-xl-flex align-items-center'>
                                            {appStoreLink && (
                                                <a
                                                    className='store-badge '
                                                    href={appStoreLink}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                >
                                                    <img
                                                        className='store-badge mb-0 mb-lg-2 mb-xl-0'
                                                        src={app_store_badge}
                                                        alt='App Store Badge'
                                                    />
                                                </a>
                                            )}
                                            {<div className='x-space'></div>}
                                            {googlePlayLink && (
                                                <a
                                                    className='store-badge'
                                                    href={googlePlayLink}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                >
                                                    <img
                                                        className='store-badge'
                                                        src={google_play_badge}
                                                        alt='Google Play Badge'
                                                    />
                                                </a>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default BoxItem;
