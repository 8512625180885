import { useTranslation } from 'react-i18next';
import { educationItems, experienceItems } from './data';
import ListItem from '../../common/listItem';
import useInView from '../../../hooks/useInView';

const Resume = () => {
    const { i18n, t } = useTranslation();
    const isRtl = i18n.language === 'he';
    const [ref, isInView] = useInView();

    const renderEducation = () => {
        return educationItems.map((item) => (
            <ListItem
                key={item._id}
                logo={item.logo}
                title={t(item.title)}
                subtitle={t(item.subtitle)}
                badgeText={t(item.badgeText)}
            />
        ));
    };

    const renderExperience = () => {
        return experienceItems.map((item) => (
            <ListItem
                key={item._id}
                logo={item.logo}
                title={t(item.title)}
                subtitle={t(item.subtitle)}
                badgeText={t(item.badgeText)}
            />
        ));
    };

    return (
        <section id='resume'>
            <div className='container'>
                <div className='text-center' ref={ref}>
                    <h3 className='section-title'>
                        <span className={`${isRtl ? 'text-primary' : 'text-tertiary'}`}>{t('resume-title.part1')}</span>
                        <span className={`${isRtl ? 'text-tertiary' : 'text-primary'}`}>{t('resume-title.part2')}</span>
                        <span className={`${isRtl ? 'text-primary' : 'text-tertiary'}`}>{t('resume-title.part3')}</span>
                        <span className={`${isRtl ? 'text-tertiary' : 'text-primary'}`}>{t('resume-title.part4')}</span>
                    </h3>
                    <div className={`row justify-content-center ${isInView ? 'fade-up' : ''}`}>
                        <div className='d-none d-md-block col-lg-1'></div>
                        <div className='col-12 col-lg-5'>
                            <h4 className='resume-subtitle'>{t('resume-experience-title')}</h4>
                            <div className='divider'></div>
                            <div className='resume-item'>{renderExperience()}</div>
                        </div>
                        <div className='col-12 col-lg-5 mb-4 mb-lg-0'>
                            <h4 className='resume-subtitle'>{t('resume-education-title')}</h4>
                            <div className='divider'></div>
                            <div className='resume-item'>{renderEducation()}</div>
                        </div>
                        <div className='d-none d-md-block col-lg-1'></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Resume;
