import validator from 'validator';

const validate = (values) => {
    const { name, email, phone, message } = values;
    const errors = {};
    if (!name) {
        errors.name = 'contact-required-field-error';
    }
    if (!email) {
        errors.email = 'contact-required-field-error';
    } else if (!validator.isEmail(email)) {
        errors.email = 'contact-invalid-email-error';
    }
    if (!phone) {
        errors.phone = 'contact-required-field-error';
    } else if (!validator.isMobilePhone(phone)) {
        errors.phone = 'contact-invalid-phone-error';
    }
    if (!message) {
        errors.message = 'contact-required-field-error';
    } else if (message.length < 10) {
        errors.message = 'contact-invalid-message-error';
    }
    return errors;
};

export default validate;
