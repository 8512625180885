import { Modal as BSModal } from 'react-bootstrap';
import spinner from '../../../assets/icons/spinner.gif';

const Modal = (props) => {
    const {
        classes,
        handleClose,
        isStatic,
        children,
        show,
        title,
        body,
        btnPrimaryText,
        btnPrimaryHandler,
        btnSecondaryText,
        btnSecondaryHandler,
        centered,
        isRtl,
        statusIcon,
        isLoading,
    } = props;

    return (
        <BSModal
            className={`${classes ? classes : ''}`}
            show={show}
            onHide={handleClose}
            backdrop={isStatic ? 'static' : true}
            keyboard={!isStatic}
            centered={centered}
            isRtl={isRtl}
        >
            {children ? (
                children
            ) : (
                <div className='text-center py-3'>
                    {isLoading ? (
                        <img className='spinner' src={spinner} alt='spinner' />
                    ) : (
                        <>
                            <img className='' src={statusIcon} alt='status icon' width={100} />
                            {title && (
                                <div className='modal-header border-0 justify-content-center pb-2'>
                                    <h5 className='modal-title fs-4 fw-bold'>{title}</h5>
                                </div>
                            )}
                            {body && <div className='modal-body fs-5 px-5 pt-2'>{body}</div>}
                            <div className='modal-footer border-0 justify-content-center pb-0'>
                                {btnSecondaryText && (
                                    <button className='btn btn-outline-primary btn-sm' onClick={btnSecondaryHandler}>
                                        {btnSecondaryText}
                                    </button>
                                )}
                                {btnPrimaryText && (
                                    <button className='btn btn-secondary btn-lg' onClick={btnPrimaryHandler}>
                                        {btnPrimaryText}
                                    </button>
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
        </BSModal>
    );
};

export default Modal;
